import React, { useEffect, useState } from 'react';
import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";
import "@babylonjs/loaders/glTF";
import {
  Engine,
  Scene,
  UniversalCamera,
  Vector3,
  SceneLoader,
  HemisphericLight,
  DirectionalLight,
  ActionManager,
  ExecuteCodeAction,
  PointerEventTypes
} from "@babylonjs/core";

import galleryGLB from "../Art/gallery/compressed.glb";
import artworks from './artworks';  

function Art() {
  const [cameraPosition, setCameraPosition] = useState({ x: 0, y: 0, z: 0 });
  const [currentArtwork, setCurrentArtwork] = useState({});
  const fixedYPosition = 2.5;  // Define a constant Y position

  useEffect(() => {
    const canvas = document.createElement("canvas");
    canvas.style.position = "fixed";
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.width = "100%";
    canvas.style.height = "100vh";
    canvas.style.zIndex = -1;
    canvas.id = "gameCanvas";
    document.body.appendChild(canvas);

    document.body.style.margin = 0;
    document.body.style.overflow = "hidden";

    const engine = new Engine(canvas, true, {
      adaptToDeviceRatio: true,
      limitDeviceRatio: true,
    });

    const scene = new Scene(engine);
    scene.collisionsEnabled = true;

    // Create a Universal Camera with an initial position
    const camera = new UniversalCamera("camera", new Vector3(-35.55, fixedYPosition, -18.65), scene);
    camera.attachControl(canvas, true);
    camera.minZ = 0.1;
    camera.applyGravity = false;
    camera.checkCollisions = true;
    camera.ellipsoid = new Vector3(1, 1, 1);

    const hemiLight = new HemisphericLight("hemiLight", new Vector3(0, 1, 0), scene);
    hemiLight.intensity = 0.6;

    const dirLight = new DirectionalLight("dirLight", new Vector3(-1, -2, -1), scene);
    dirLight.position = new Vector3(-12.54, 29.37, -79.41);
    dirLight.intensity = 0.7;

    engine.displayLoadingUI();

    SceneLoader.ImportMeshAsync("", galleryGLB, "").then(function (result) {
      engine.hideLoadingUI();
      result.meshes.forEach(mesh => {
        mesh.checkCollisions = true;

        if (artworks[mesh.name]) {
          mesh.actionManager = new ActionManager(scene);
          mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
            setCurrentArtwork(artworks[mesh.name]);
          }));
        }
      });
    });

    // Track pointer touch movements
    let isDragging = false;
    let lastX = 0;
    let lastY = 0;

    scene.onPointerObservable.add((pointerInfo) => {
      switch (pointerInfo.type) {
        case PointerEventTypes.POINTERDOWN:
          if (pointerInfo.event.pointerType === "touch") {
            isDragging = true;
            lastX = pointerInfo.event.clientX;
            lastY = pointerInfo.event.clientY;
          }
          break;
        case PointerEventTypes.POINTERUP:
          if (pointerInfo.event.pointerType === "touch") {
            isDragging = false;
          }
          break;
        case PointerEventTypes.POINTERMOVE:
          if (isDragging && pointerInfo.event.pointerType === "touch") {
            const deltaX = pointerInfo.event.clientX - lastX;
            const deltaY = pointerInfo.event.clientY - lastY;

            // Adjust the camera's rotation around the Y and X axes based on finger movement
            const rotationSpeed = 0.002; // Sensitivity of the rotation (can be tweaked)
            camera.rotation.y -= deltaX * rotationSpeed; // Rotate horizontally (yaw)
            camera.rotation.x -= deltaY * rotationSpeed; // Rotate vertically (pitch)

            // Update the last touch position
            lastX = pointerInfo.event.clientX;
            lastY = pointerInfo.event.clientY;
          }
          break;
        default:
          break;
      }
    });

    engine.runRenderLoop(() => {
      camera.position.y = fixedYPosition; // Lock Y axis
      scene.render();
      setCameraPosition({
        x: parseFloat(camera.position.x.toFixed(2)),
        y: parseFloat(camera.position.y.toFixed(2)),
        z: parseFloat(camera.position.z.toFixed(2)),
      });
    });

    window.addEventListener('resize', () => {
      engine.resize();
    });

    return () => {
      engine.dispose();
      document.body.removeChild(canvas);
      document.body.style.margin = "";
      document.body.style.overflow = "";
    };
  }, []);

  const handleClose = () => {
    setCurrentArtwork({});
  };

  return (
    <>
      <div style={{
        position: 'fixed',
        top: '10px',
        right: '10px',
        padding: '5px 10px',
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: 'white',
        borderRadius: '5px',
        zIndex: 1000,
      }}>
        X: {cameraPosition.x} | Y: {cameraPosition.y} | Z: {cameraPosition.z}
      </div>
      {currentArtwork.image && (
        <div style={{
          position: 'fixed',
          bottom: '10px',
          left: '10px',
          width: '90%',
          maxWidth: '300px',
          padding: '10px',
          backgroundColor: 'rgba(0,0,0,0.8)',
          color: 'white',
          borderRadius: '10px',
          zIndex: 1000,
          textAlign: 'center',
        }}>
          <button onClick={handleClose} style={{
            position: 'absolute',
            top: '5px',
            right: '10px',
            border: 'none',
            background: 'transparent',
            color: 'white',
            fontSize: '24px',
            cursor: 'pointer',
          }}>×</button>
          <div>{currentArtwork.artistName}</div>
          <div style={{ marginTop: '5px', fontStyle: 'italic' }}>{currentArtwork.paintingDescription}</div>
          <img src={currentArtwork.image} alt={currentArtwork.paintingDescription} style={{
            width: '100%',
            marginTop: '10px',
            maxHeight: '200px',
            objectFit: 'contain',
          }} />
          <div style={{ marginTop: '5px' }}>{currentArtwork.currentDescription}</div>
        </div>
      )}
    </>
  );
}

export default Art;
